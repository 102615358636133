<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Stylist Assist</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Appointments
            <span class="tableTotal font-15 poppins">(Total : {{ appointments.length }})</span>
          </div>
          <div class="float-right d-flex mx-4">
            <!-- <div class="pr-6">
              <form class="search-form mr-form">
                <input
                  type="text "
                  class="form-control"
                  v-model="searchText"
                  @input="viewUserList(1)"
                  placeholder="Search..."
                />
              </form>
            </div> -->
            <div class="float-right">
              <router-link to="/createAppointment" class="Create-btn btn btn-success btn-small"
                >Create</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-2">Name</th>

                      <th class="col-md-3">Email</th>

                      <th class="col-md-2">Mobile</th>
                      <th class="col-md-2">Date</th>
                      <th class="col-md-2">Slot Time</th>
                      <th colspan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in appointments" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div>{{ item.userData[0].name }}</div>
                      </td>

                      <td>
                        <div>{{ item.email }}</div>
                      </td>

                      <td>
                        <div>{{ item.connectWith }}</div>
                      </td>
                      <td>
                        <div v-if="item.date">{{ item.date | moment }}</div>
                      </td>
                      <td>
                        <div>{{ item.slotsTime }}</div>
                      </td>
                      <!-- v-repeat -->
                      <td>
                        <div class="d-flex">
                          <!-- <div class="mr-8">
                            <router-link
                              class="btn btn-primary"
                              :to="{
                                name: 'editAppointment',
                                params: { id: item._id },
                              }"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </router-link>
                          </div> -->

                          <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  :to="{
                                    name: 'editAppointment',
                                    params: { id: item._id },
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-b-modal.delete-modal
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                          <!-- <div>
                                <deletemodal v-bind:mymodal="item"></deletemodal>
                              </div> -->
                          <!-- <div class="deletedModal">
                            <b-button
                              variant="danger"
                              v-b-modal.delete-modal
                              @click="deleteAppointmentById(item._id)"
                            >
                              <b-icon-trash></b-icon-trash>
                            </b-button>
                          </div> -->
                        </div>
                      </td>
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="appointments.length === 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="appointments.length >= 1">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
        @input="getAllAppointments(page)"
      />
      <!-- <b-pagination
        class="mb-2 float-center justify-center"
        v-model="page"
        :total-rows="totalPages"
        :per-page="20"
        @input="getAllAppointments(page)"
      /> -->
    </div>
    <ConfirmDlg ref="confirm" />  
  </div>
</template>

<script>
import appointmentService from '../../services/appointmentService';
import moment from 'moment';
import ConfirmDlg from '../../components/ConfirmDlg.vue';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      appointments: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 1,
      errors: [],
      totalPages: 1,
    };
  },
  created() {
    this.getAllAppointments(this.page);
  },
  methods: {
    async getAllAppointments(pageNo) {
      console.log('result');
      //  this.users = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;

      const result = await appointmentService.getAllAppointments({
        page: pageNo,
        vendor: JSON.parse(localStorage.getItem('selectedVendor')),
      });
      this.appointments = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      //
      console.log('result', this.appointments);
    },
    async deleteAppointmentById(id) {
      const result = await appointmentService.deleteAppointment(id);

      //
      if (result.status === 200) {
        await this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      console.log('result', this.appointments);
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteAppointmentById(id);
        console.log('got confirmation');
      }
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
