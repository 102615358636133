import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
const adminIframeUrl = process.env.VUE_APP_WL_API_URL;

// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getAllAppointments(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminIframeUrl}/iframe/appointment/getAllAppointments`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneAppointment(params) {
    console.log('working');
    return axios.get(`${adminIframeUrl}/iframe/appointment/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateAppointment(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminIframeUrl}/iframe/appointment/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async deleteAppointment(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminIframeUrl}/iframe/appointment/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createAppointment(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminIframeUrl}/iframe/appointment/createFromAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
